<template>
  <div class="pdata">
    <div class="pdata__info">
      <div class="pdata__info-selectors">
        <label class="pdata__label" style="display: flex; align-items: center; gap: 10px;">
          Конкретная дата
          <ToggleInput v-model="particular" />
        </label>
        <label class="pdata__label" v-if="particular">
          Дата
          <div class="pdata__doublebtns">
            <select class="pdata__input" v-model="pardate" style="width: 100%;">
              <option v-for="date in availableDates" :value="dateToOk(date)" :key="date">{{ date.toLocaleDateString('fr-CH') }}</option>
            </select>
          </div>
        </label>
        <label class="pdata__label" v-else>
          Даты
          <div class="pdata__doublebtns">
            <input class="pdata__input" type="date" v-model="dates[0]" :max="dates[1]">
            <div class="pdata__doublebtns-dash">—</div>
            <input class="pdata__input" type="date" v-model="dates[1]" :min="dates[0]">
          </div>
        </label>
        <label class="pdata__label">
          Группа
          <select class="pdata__input" style="display: block;width: 100%;" v-model="group" ref="group_select">
            <option value="null">---Все группы---</option>
            <option v-for="group in project.groups" :value="group" :key="project.name + ' - ' + group">{{ group }}</option>
          </select>
        </label>
        <label class="pdata__label" v-if="!particular">
          Столбец
          <select class="pdata__input" style="display: block;width: 100%;" v-model="column">
            <option v-for="col in Object.keys(project.colnames)" :value="col" :key="project.name + ' - ' + col">{{ decodeKey(col) }}</option>
          </select>
        </label>
        <div class="pdata__selector" v-for="s, i in selectors" :key="i">
          <label class="pdata__label" style="margin-bottom: 0;">Запрос</label>
          <input class="pdata__input" type="text" v-model="selectors[i]">
        </div>
        <div class="pdata__doublebtns" v-if="false">
          <Btn :small="true" @click="selectors.push('')">Добавить</Btn>
          <Btn :small="true" v-if="selectors.length" @click="selectors.pop()">Удалить</Btn>
        </div>
        <Btn @click="getTableData" class="pdata__getdata">Получить данные</Btn>
        <div class="filler"></div>
        <div class="pdata__btn" v-if="project && Role == 2">
          <label class="pdata__label">
            Парсить группы:
            <select class="pdata__input" style="display: block;width: 100%;" v-model="parseGroups">
              <option value="-1">Определять автоматически</option>
              <option value="1">Да</option>
              <option value="0">Нет</option>
            </select>
          </label>
          <Btn class="pdata__btn" @click="$refs.file.click()">Загрузить файл</Btn>
          <input class="pdata__file" ref="file" type="file" name="excel" @change="sendFile">
        </div>
      </div>
      <div class="pdata__info-data">
        <Table :pardate="pardate" />
      </div>
    </div>
  </div>
</template>

<script>
import Btn from './buttons/Btn.vue';
import { mapState } from 'vuex'
import Table from './Table.vue';
import ToggleInput from './ToggleInput.vue';
export default {
  data: () => ({
    pardate: new Date('01/01/2023').toLocaleDateString('en-CA'),
    selectors: [''],
    availableDates: [],
    group: 'Без группы',
    parseGroups: "-1",
    dates: [new Date('01/01/2023').toLocaleDateString('en-CA'), new Date().toLocaleDateString('en-CA')]
  }),
  computed: {
    ...mapState(["project", "Role",'url']),
    particular: {
      get() {
        return this.$store.state.tableparticular
      },
      set(v) {
        this.$store.commit('setTableParticular', v)
      }
    },
    column: {
      get() {
        return this.$store.state.column
      },
      set(v) {
        this.$store.commit('setColumn', v)
      }
    }
  },
  components: { Btn, Table, ToggleInput },
  methods: {
    dateToOk(date) {
      const yyyy = date.getFullYear() + ''
      const mm = ((date.getMonth() + 1) + '').padStart(2, '0')
      const dd = (date.getDate() + '').padStart(2, '0')
      return `${yyyy}-${mm}-${dd}`
    },
    decodeKey(key) {
      return key.replace(/\\u002e/g, ".").replace(/\\u0024/g, "$").replace(/\\\\/g, "\\")
    },
    async getDates() {
      this.availableDates = (await this.$store.dispatch('getDates', { group: this.group })).sort((a, b) => b - a)
    },
    async sendFile() {
      let a = new FormData()
      a.append('excel', this.$refs.file.files[0])
      a.append('project', this.project.name)
      a.append('parseGroups', this.parseGroups)
      try {
        await this.$store.dispatch('sendFile', a)
      } catch (err) {
        alert(err)
      }
      await this.$store.dispatch('updateProjects', a)
      this.$refs.file.value = ''
    },
    async getTableData() {
      let group = this.group
      if (this.$refs.group_select.selectedIndex === 0)
        group = null
      if (this.particular)
        return this.$store.dispatch('getTableDateData', {
          regexs: this.selectors,
          date: this.pardate,
          group: group
        })
      else
        this.$store.dispatch('getTableData', {
          regexs: this.selectors,
          dates: this.dates,
          column: this.column,
          group: group
        })
    }
  },
  watch: {
    '$store.state.group'(v) {
      console.log(v)
      this.group = v
    },
    particular(v, old) {
      if (v != old) {
        this.getDates()
      }
    },
    project(){
      if (this.particular)
        this.getDates()
    },
    group(){
      if (this.particular)
        this.getDates()
    }
  } 
}
</script>

<style lang="sass" scoped>
.pdata
  padding-top: 30px
  display: flex
  flex-direction: column
  &__getdata
    width: 100% !important
    min-width: 100% !important
    max-width: 100% !important
    flex-shrink: 0 !important
  .filler
    flex-grow: 1
  &__label
    display: block
  &__input
    padding: 5px 8px
    border-radius: 4px
    border: 1px solid rgba(0,0,0,.4)
    outline: none
    display: block
    width: 100%
    &:not([type=text])
      cursor: pointer
    &:focus
      border-color: rgba(0,0,0,.8)
  &__info
    display: flex
    flex-grow: 1
    align-items: stretch
    &-data
      flex-grow: 1
      display: flex
      flex-direction: column
    &-selectors
      flex-shrink: 0
      width: 250px
      position: sticky
      top: 10px
      min-height: calc(100vh - 80px)
      align-self: flex-start
      display: flex
      flex-direction: column
      margin-right: 15px
      >*
        margin-bottom: 10px
  &__doublebtns
    display: flex
    justify-content: space-between
    position: relative
    &-dash
      width: auto !important
      position: absolute
      left: 50%
      transform: translateX(-50%)
      top: 0
      font-size: 16px
      line-height: 30px
    >*
      width: calc(50% - 12.5px)
  &__file
    position: absolute
    opacity: 0
    pointer-events: none
  &__btn
    margin-top: 30px
    width: 100%
    align-self: stretch
</style>